import React from 'react';
import Layout from '../layouts/layout'

function Terms() {
    return (
        <div>
            <Layout headerClass="bg-black h-[122px] relative">
                <div className='flex justify-center'>

                    <div className="container info-content py-20">
                    Welcome to KONTENTIFY.io, your supportive community dedicated to making learning a more enjoyable and productive experience. As members of this community, we adhere to a set of guidelines that ensure our platform remains a place of respect, curiosity, and authentic learning.

Commitment to Learning. KONTENTIFY.io is a bridge to knowledge and personal growth. We believe in the power of learning and encourage you to explore, ask questions, and dive deep into your areas of interest. Use our platform to understand, clarify, and expand your academic horizons, but remember: we are here to guide your learning journey, not to do the work for you.

Academic Integrity. Honesty is the cornerstone of any educational endeavor. KONTENTIFY.io stands firmly against academic dishonesty in any form, including plagiarism and cheating. Our mission is to support your personal and academic growth, not to offer shortcuts or completed assignments. Use our resources to enhance your understanding and improve your skills, not to bypass the learning process.

Respect for Our Community. At KONTENTIFY.io, every voice matters. Whether you're a user seeking assistance or a tutor sharing knowledge, you're a valued member of our community. We expect all users to treat each other with respect, patience, and kindness. Let's create a positive atmosphere for everyone to share, learn, and grow.

Personal Privacy. Protecting your personal information is paramount to us, and we expect the same commitment from our community members. Sharing or soliciting personal contact information is strictly prohibited. We're here to foster a safe, trusting environment where your privacy is respected.

Valuing the Process. Learning is a process, not a one-time event. It requires time, effort, and the ability to make mistakes from which you can learn. At KONTENTIFY.io, we understand and appreciate this process. We assist you in your pursuit of knowledge, not by providing ready-made answers or solutions. By using our services, you agree to use the provided information as a supplementary tool aimed at understanding, not as a finished version of your work. You affirm that any information obtained from KONTENTIFY.io will be used in accordance with the academic standards and regulations of your educational institution.

By adhering to these principles, we can ensure that KONTENTIFY.io remains a vibrant, engaging, and productive platform. If you witness any behavior that contradicts our Honor Code, please don't hesitate to contact us at support@KONTENTIFY.io.com. Let's work together to uphold the integrity and purpose of our learning community.
                        
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default Terms;